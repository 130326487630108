/* * * * * * * * * *
+320px :: 20em
+480px :: 30em
+600px :: 37.50em
+768px :: 48em
+992px :: 62em
+1024px :: 64em
+1140px :: 71.250em
+1224px :: 76.5em
+1382px :: 86.375em
* * * * * * * * * */
/* Media Queries*/
/* Phones */
/* Tablets*/
/* Tablets Horizontal*/
/* Desktops, slightly less large devices */
/* Desktops, large devices */
/* Desktops, very large devices */
/* Desktops, top navigation */
/* px and rem */
/* -----------------------------
   FUNCTIONS
   ----------------------------- */
/*
Removes the unit (e.g. px, em, rem) from a value, returning the number only.
@param {Number} $num - Number to strip unit from.
@returns {Number} The same number, sans unit.
*/
/*
Converts one or more pixel values into matching rem values.
@param {Number|List} $values - One or more values to convert. Be sure to separate them with spaces and not commas. If you need to convert a comma-separated list, wrap the list in parentheses.

@param {Number} $base [null] - The base value to use when calculating the `rem`. If you're using Foundation out of the box, this is 16px. If this parameter is `null`, the function will reference the `$base-font-size` variable as the base.

@returns {List} A list of converted values.
*/
/*
Converts a pixel value to matching rem value. *Any* value passed, regardless of unit, is assumed to be a pixel value. By default, the base pixel value used to calculate the rem value is taken from the `$global-font-size` variable.
@access private
@param {Number} $value - Pixel value to convert.
@param {Number} $base [null] - Base for pixel conversion.
@returns {Number} A number in rems, calculated based on the given value and the base pixel value. rem values are passed through as is.
*/
.layoutOnePageNux header .zone-logo {
  padding-top: 9.5px;
}
@media screen and (min-width: 48em) {
  .layoutOnePageNux header .zone-logo {
    padding-top: 16px;
  }
}
.layoutOnePageNux .btn-langue {
  display: block !important;
  top: 9.5px !important;
  width: 40px;
  height: 40px;
  border-color: #e0e4e9;
}
@media screen and (min-width: 48em) {
  .layoutOnePageNux .btn-langue {
    top: 16px !important;
    width: 50px;
    height: 50px;
  }
}

.lien-externe {
  font-weight: 600 !important;
  text-decoration: none;
}
.lien-externe:hover {
  text-decoration: underline;
}

.btn.btn-lien-externe {
  display: inline-block;
  padding: 12.5px 32px;
  line-height: 1;
  vertical-align: middle;
}
.btn.btn-lien-externe:after {
  margin-left: 6px;
  line-height: 1.5;
}

.cta.cta-bleu-vif {
  width: 100%;
  margin: 0 16px 16px 0;
  padding: 0 32px;
  border-radius: 3px;
  -webkit-box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.25);
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.25);
  background-color: rgb(128, 222, 234);
  -webkit-transition: background-color 0.35s ease;
  transition: background-color 0.35s ease;
}
@media screen and (min-width: 48em) {
  .cta.cta-bleu-vif {
    max-width: 214px;
  }
}
.cta.cta-bleu-vif:hover {
  background-color: rgba(128, 222, 234, 0.75);
  -webkit-transition: background-color 0.35s ease;
  transition: background-color 0.35s ease;
}
.cta.cta-bleu-vif span {
  color: #003da5 !important;
}

.semi-bold {
  font-weight: 600;
}

.heroV2.visible {
  min-height: 585px;
  background-size: auto 100%;
}
@media screen and (min-width: 71.25em) {
  .heroV2.visible {
    height: 585px;
  }
}
.heroV2 .zone-hero-texte {
  max-width: 570px;
  padding: 0;
}
.heroV2 .zone-hero-texte .hero-separateur {
  display: none;
}
.heroV2 .zone-hero-texte .hero-titre {
  margin-bottom: 24px;
  font-size: 3rem;
  line-height: normal;
}
@media screen and (min-width: 48em) {
  .heroV2 .zone-hero-texte .hero-titre {
    font-size: 3.286rem;
  }
}
.heroV2 .zone-hero-texte .hero-sous-titre {
  max-width: none;
  line-height: normal;
}
.heroV2 .zone-hero-texte .hero-sous-titre h2 {
  font-size: 21px;
}

.layoutOnePageNux footer {
  background-color: #fff;
}
.layoutOnePageNux footer .btn-langue {
  display: none !important;
}

.grille-section-intro .bloc-contenu-communique-de-presse {
  padding: 48px 16px;
}
@media screen and (min-width: 62.5em) {
  .grille-section-intro .bloc-contenu-communique-de-presse {
    padding: 60px 190px;
  }
}
.grille-section-intro .bloc-contenu-communique-de-presse p:last-of-type {
  margin-bottom: 24px;
}
.grille-section-intro .titre-accordeon-content {
  margin-bottom: 12px;
}
.grille-section-intro .grille-liens .bloc-contenu {
  margin: 0 0 24px;
}
.grille-section-intro .grille-liens .bloc-contenu:last-child {
  margin: 0;
}
.grille-section-intro .grille-liens .bloc-contenu:first-child p {
  margin-top: 0;
}
.grille-section-intro .grille-liens .bloc-contenu .btn-lien-externe {
  width: 100%;
  max-width: none;
  margin: 0;
  padding: 12.5px 14px;
}
.grille-section-intro .grille-liens .btn {
  margin: 0;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 95%;
}
@media screen and (min-width: 71.25em) {
  .grille-section-intro .grille-liens .btn {
    max-width: 190px;
  }
}
.grille-section-intro .bloc-contenu-section-pour-les-conseillers p {
  margin: 0 0 12px;
}
.grille-section-intro .bloc-contenu-section-pour-les-conseillers ul {
  margin-top: 0;
}

.grille-section-produits .bloc-contenu-titre {
  max-width: 855px;
}
.grille-section-produits .grille-5-colonnes .col-1-1 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.grille-section-produits .grille-5-colonnes .col-1-1 .bouton-fond-blanc {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 100%;
  flex: 1 1 100%;
  margin: 8px 0;
}
@media screen and (min-width: 62.5em) {
  .grille-section-produits .grille-5-colonnes .col-1-1 .bouton-fond-blanc {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 0px;
    flex: 1 1 0;
    margin: 0 8px;
  }
}
.grille-section-produits .grille-5-colonnes .col-1-1 .bouton-fond-blanc:first-child {
  margin-top: 0;
  margin-left: 0;
}
.grille-section-produits .grille-5-colonnes .col-1-1 .bouton-fond-blanc:last-child {
  margin-right: 0;
  margin-bottom: 0;
}

.grille-section-faq .commun-accordeon .titre-bloc-accordeon {
  margin: 0 0 16px;
  font-family: "Open Sans", helvetica, arial;
  font-size: 1.214rem;
}
.grille-section-faq .commun-accordeon .commun-element-accordeon-item-renderer .contenu-accordeon-container {
  border: solid 2px #eff1f4;
  background-color: #fff;
}
.grille-section-faq .commun-accordeon .commun-element-accordeon-item-renderer .contenu-accordeon-container .btn {
  margin-top: 10px;
}
.grille-section-faq .commun-accordeon .commun-element-accordeon-item-renderer .contenu-accordeon-container p {
  font-size: 17px;
}
.grille-section-faq .commun-accordeon .commun-element-accordeon-item-renderer .titre-accordeon .titre-accordeon-content {
  font-family: "Open Sans", helvetica, arial;
}
@media screen and (min-width: 62.5em) {
  .grille-section-faq .commun-accordeon .commun-element-accordeon-item-renderer .titre-accordeon .titre-accordeon-content {
    max-width: 75%;
  }
}
.grille-section-faq .accordeon-triangle.commun-accordeon .titre-accordeon {
  background-color: #eff1f4;
}
.grille-section-faq .accordeon-triangle.commun-accordeon .titre-accordeon.section-active {
  background-color: #003da5;
}

.grille-section-reclamation {
  padding-top: 348px;
  background-size: auto 300px;
  background-position: right 18% top;
}
@media screen and (min-width: 48em) {
  .grille-section-reclamation {
    padding-top: 48px;
    background-size: auto 100%;
    background-position: right 32% center;
  }
}
@media screen and (min-width: 71.25em) {
  .grille-section-reclamation {
    background-position: center center;
  }
}

.grille-section-map {
  padding: 0;
}
.grille-section-map .grille-2-colonnes {
  top: 96px;
  right: 0;
  left: 0;
  z-index: 1;
}
@media screen and (min-width: 62.5em) {
  .grille-section-map .grille-2-colonnes {
    position: absolute;
  }
}
.grille-section-map .grille-2-colonnes .deux-Colonnes-Gauche {
  padding: 0;
  background-color: #fff;
}
.grille-section-map .grille-2-colonnes .grille-contact-1 {
  z-index: 3;
}
.grille-section-map .grille-2-colonnes .grille-contact-2 {
  z-index: 2;
}
.grille-section-map .grille-contact-1 .bloc-commun-liste-contacts h3 {
  padding-bottom: 36px;
  border-bottom: 1px solid #e0e4e9;
  font-size: 28px;
}
.grille-section-map .bloc-commun-liste-contacts h5 {
  margin: 0 0 4px;
  font-size: 17px;
  font-weight: bold;
}
.grille-section-map .bloc-commun-liste-contacts a {
  text-decoration: none;
}
.grille-section-map .bloc-commun-liste-contacts ul {
  margin-top: 0;
}
.grille-section-map .bloc-commun-liste-contacts .details-contact > li:last-child {
  margin-top: 0;
}
.grille-section-map .bloc-commun-liste-contacts .details-contact > li ul li {
  margin: 0 0 8px;
}
.grille-section-map .grille-base {
  max-width: none;
}
.grille-section-map .commun-google-map {
  height: 1098px;
}

.grille-section-formulaire .reponse-formulaire-nux {
  background-color: #e0e4e9;
}